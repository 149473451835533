<script setup>
    import { provide } from 'vue';

    import { TweakPane } from '@resn/gozer-vue';

    import MainProvider from '~/providers/instant-rips/MainProvider.vue';

    provide('sandbox', true);
</script>

<template>
    <TweakPane>
        <MainProvider>
            <div class="layout-sandbox">
                <slot />
            </div>
        </MainProvider>
    </TweakPane>
</template>
